/* eslint-disable no-plusplus */

import { Chip, IconButton, Switch, Tooltip } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import MDBox from "components/Basics/MDBox";
import MDTypography from "components/Basics/MDTypography";
import { useEffect, useState } from "react";
import MDInput from "components/Basics/MDInput";
import { Add, Delete } from "@mui/icons-material";

import i18n from "i18n";
import lod_ from "lodash";
import { selectAllSortedMenu } from "redux-react/reducers/profileReducer";
import DictionaryMenu from "pages/settings/filters/DictionaryMenu";
import MDButton from "components/Basics/MDButton";
import { selectUser } from "redux-react/reducers/userReducers";

const FormRule = ({ submit, dictionary = {} }) => {
	const [name, setName] = useState("");
	const [code, setCode] = useState("");
	const [desc, setDesc] = useState("");
	const [system, setSystem] = useState(false);
	const pages = useSelector(selectAllSortedMenu);
	const [selectedDictionnary, setSelectedDictionnary] = useState([]);
	const [showWarning, setShowWarning] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);
	const user = useSelector(selectUser);

	useEffect(() => {
		if (selectedDictionnary.length > 0 && name.trim() !== "" && code.trim() !== "") {
			submit({
				valid: true,
				data: {
					name,
					code,
					desc,
					system,
					inputDictionary: selectedDictionnary
				}
			});
		} else {
			submit({
				valid: false
			});
		}
	}, [name, code, selectedDictionnary]);

	const getPath = path => {
		const parts = path.split(".");
		const result = [];
		let current = "";

		for (let i = 0; i < parts.length; i++) {
			current += parts[i];
			result.push(current);
			current += ".items.";
		}

		let stringPath = "";

		for (let i = 0; i < result.length; i++) {
			stringPath += " " + lod_.get(dictionary, result[i] + ".label.fr") + " /" || "";
		}

		stringPath = stringPath.slice(0, -1);
		return stringPath;
	};

	const addItem = item => {
		let labelPath = getPath(item);
		let route = item.replaceAll(".", ".items.");
		let itemLabel = lod_.get(dictionary, route)?.label?.fr;
		setAnchorEl(null);
		let copySelectedD = lod_.cloneDeep(selectedDictionnary);
		copySelectedD.push({ label: itemLabel, path: route, labelPath });
		setSelectedDictionnary(copySelectedD);
	};

	const deleteItem = path => {
		let copySelectedD = lod_.cloneDeep(selectedDictionnary);
		copySelectedD = copySelectedD.filter(element => element.path !== path);
		setSelectedDictionnary(copySelectedD);
	};

	const checkCodeItem = text => {
		let regex = /^[A-Za-z]+$/;
		if (regex.test(text)) {
			setCode(text);
		} else if (text === "") {
			setCode(text);
		} else {
			setShowWarning(true);
			setTimeout(() => {
				setShowWarning(false);
			}, 3000);
		}
	};

	return (
		<MDBox>
			<MDTypography variant="h6">{i18n.t("SETTINGS.RULES.newRule")}</MDTypography>
			<MDBox display="flex" flexDirection="column" mt={1}>
				<MDBox>
					<MDInput
						className="dialogInput"
						value={name}
						onChange={e => setName(e.target.value)}
						label={
							<span>
								{i18n.t("SETTINGS.RULES.ruleName")}
								<span className="mandatoryField">*</span>
							</span>
						}
					/>
				</MDBox>
				<MDBox mt={1}>
					<Tooltip
						open={showWarning}
						title={i18n.t("SETTINGS.RULES.regexCode")}
						placement="right"
						disableFocusListener
						disableHoverListener
						disableTouchListener
					>
						<MDInput
							className="dialogInput"
							value={code}
							onChange={e => checkCodeItem(e.target.value)}
							label={
								<span>
									{i18n.t("SETTINGS.RULES.ruleCode")}
									<span className="mandatoryField">*</span>
								</span>
							}
						/>
					</Tooltip>
				</MDBox>
				<MDBox mt={1}>
					<MDInput
						className="dialogInput"
						value={desc}
						onChange={e => setDesc(e.target.value)}
						label={<span>{i18n.t("SETTINGS.RULES.ruleDesc")}</span>}
					/>
				</MDBox>

				{user.system && (
					<MDBox
						mt={1}
						display="flex"
						justifyContent="space-between"
						alignItems="center"
						className="customSwitchContainer"
						onClick={() => setSystem(!system)}
					>
						{i18n.t("SETTINGS.RULES.ruleSystem")}
						<Switch checked={Boolean(system)} />
					</MDBox>
				)}

				<MDBox mt={1}>{i18n.t("SETTINGS.RULES.inputDictionary")}</MDBox>

				<MDBox mt={2} mb={1}>
					{!lod_.isEmpty(dictionary) ? (
						Object.keys(dictionary)
							.sort((a, b) => dictionary[a]?.label?.fr.localeCompare(dictionary[b]?.label?.fr))
							.map((child, key) => {
								return (
									<Chip
										style={{
											textTransform: "initial",
											cursor: "pointer",
											marginRight: "1%",
											marginBottom: "1%"
										}}
										color={
											selectedDictionnary.find(objet => objet.path === child) ? "info" : "default"
										}
										label={dictionary[child]?.label?.fr}
										onClick={() =>
											selectedDictionnary.find(objet => objet.path === child)
												? deleteItem(child)
												: addItem(child)
										}
										icon={
											selectedDictionnary.find(objet => objet.path === child) ? <Delete /> : <Add />
										}
									/>
								);
							})
					) : (
						<div>{i18n.t("SETTINGS.RULES.emptyInput")}</div>
					)}
				</MDBox>
			</MDBox>
		</MDBox>
	);
};

export default FormRule;
