/* eslint-disable prefer-destructuring */
import {
	Autocomplete,
	FormControl,
	Icon,
	InputAdornment,
	InputLabel,
	MenuItem,
	Select
} from "@mui/material";
import MDBox from "components/Basics/MDBox";
import MDInput from "components/Basics/MDInput";
import lod_ from "lodash";
import { useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { selectCurrentProfile } from "redux-react/reducers/profileReducer";
import MDTypography from "components/Basics/MDTypography";

const WhiteListItem = ({
	label,
	disabled,
	value,
	onChange,
	item,
	listOfApis,
	error,
	InputProps,
	...rest
}) => {
	const assistantIDs = useSelector(state => selectCurrentProfile(state).assistantID);
	const { user } = useSelector(state => state);

	const [valueAuto, setValueAuto] = useState(value);
	const [optionAuto, setOptionAuto] = useState([]);

	let whitelist = item.whitelist || [];
	let whitelistDynamic = item.whitelistDynamic || {};
	let type = item.type;

	async function loadSuggestions(searchText, collection, searchFields, apiName, action, limit = 10) {
		let querryData = [];
		searchFields.forEach(field => {
			const query = {};
			query[field] = { $regex: searchText };
			querryData.push(query);
		});

		let attribute = whitelistDynamic?.params?.attribute;

		querryData.push({ [attribute]: { $regex: searchText } });

		let dataQuerry = JSON.stringify({
			query: {
				$or: querryData
			}
		});

		let APIFind;
		let actionFind;
		if (apiName && action) {
			APIFind = listOfApis.find(item => item.name === apiName) || {};
			actionFind = APIFind.config.action[action];
		} else {
			return [];
		}

		let account = actionFind?.account ?? false;

		collection = collection || actionFind?.collection;
		let config = {
			method: actionFind?.method ?? "GET",
			maxBodyLength: Infinity,
			url: `${APIFind.config.host}/api/v1/ressource/${collection}/items?search=${searchText}&page=1&limit=${limit}&account=${account}`,
			headers: {
				Authorization: `Bearer ${user.token}`,
				assistantID: assistantIDs,
				"Content-Type": "application/json"
			}
		};

		if (config.method === "GET") {
			config.data = dataQuerry;
		} else {
			config.data = {
				filter: actionFind?.filter,
				searchFields,
				attribute,
				query: {
					$or: querryData
				}
			};
		}

		let result;
		await axios
			.request(config)
			.then(response => {
				result = response.data;
			})
			.catch(error => {
				// console.log(error);
			});

		return result?.results || [];
	}

	const ValueChange = async valueC => {
		setValueAuto(valueC);

		let result = await loadSuggestions(
			valueC,
			whitelistDynamic.params.collection,
			whitelistDynamic.params.searchFields,
			whitelistDynamic.apiName,
			whitelistDynamic.action
		);

		setOptionAuto(result);
	};

	const getOptionLabel = option => {
		let labelAttribute = option[whitelistDynamic?.params?.attribute] || "";
		let label = whitelistDynamic.params.searchFields.map(key => option[key]).join(" - ");
		label = labelAttribute + " - " + label;
		return label;
	};

	const onChangeDynamic = (event, newValue, option) => {
		if (option === "selectOption") {
			setValueAuto(newValue[whitelistDynamic.params.attribute]);
			onChange(newValue[whitelistDynamic.params.attribute]);
		} else if (option === "clear") {
			setValueAuto("");
			onChange("");
		}
	};

	if (lod_.isArray(whitelist) && !lod_.isEmpty(whitelist)) {
		return (
			<MDBox mt={1}>
				<FormControl fullWidth>
					<InputLabel id="select-label">{label}</InputLabel>
					<Select
						labelId="select-label"
						id="select"
						label={label}
						value={value || null}
						onChange={e => {
							onChange(e.target.value);
						}}
					>
						{whitelist.map((option, index) => (
							<MenuItem key={index} value={option}>
								{option}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</MDBox>
		);
	} else if (whitelistDynamic && !lod_.isEmpty(whitelistDynamic)) {
		return (
			<Autocomplete
				id="auto"
				freeSolo
				options={optionAuto}
				onChange={onChangeDynamic}
				getOptionLabel={getOptionLabel}
				inputValue={valueAuto || ""}
				renderInput={params => (
					<MDInput {...params} label={label} onChange={e => ValueChange(e.target.value)} />
				)}
			/>
		);
	} else {
		return (
			<>
				<MDInput
					disabled={disabled}
					type={type}
					className="dialogInput"
					label={label}
					value={value}
					onChange={e => {
						if (type === "number") {
							onChange(parseFloat(e.target.value));
						} else {
							onChange(e.target.value);
						}
					}}
					InputProps={{
						endAdornment: error ? (
							<InputAdornment
								position="end"
								style={{
									pointerEvents: "auto"
								}}
							>
								<Icon fontSize="medium" color="error">
									info
								</Icon>
							</InputAdornment>
						) : null
					}}
					{...InputProps}
					{...rest}
				/>
				<MDTypography
					variant="caption"
					color="error"
					style={{
						display: error ? "block" : "none"
					}}
				>
					Cette valeur doit être unique
				</MDTypography>
			</>
		);
	}
};
export default WhiteListItem;
