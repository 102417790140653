/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/* eslint-disable no-dupe-keys */
// Material Dashboard 2 PRO React base styles
import colors from "assets/theme/base/colors";

const { gradients, dark } = colors;

function configs(labels, datasets) {
	const backgroundColors = [];

	if (datasets.backgroundColors) {
		datasets.backgroundColors.forEach(color =>
			gradients[color] ? backgroundColors.push(gradients[color].state) : backgroundColors.push(color)
		);
	} else {
		backgroundColors.push(dark.main);
	}

	return {
		data: {
			labels,
			datasets: [
				{
					...datasets,
					weight: 9,
					cutout: 0,
					tension: 0.9,
					pointRadius: 2,
					borderWidth: 2,
					backgroundColor: backgroundColors,
					fill: false
				}
			]
		},
		options: {
			layout: {
				padding: {
					top: 25,
					bottom: 25
				}
			},
			responsive: true,
			maintainAspectRatio: false,
			plugins: {
				legend: {
					display: true,
					position: "left"
				}
			},
			scales: {
				y: {
					grid: {
						drawBorder: false,
						display: false,
						drawOnChartArea: false,
						drawTicks: false
					},
					ticks: {
						display: false
					}
				},
				x: {
					grid: {
						drawBorder: false,
						display: false,
						drawOnChartArea: false,
						drawTicks: false
					},
					ticks: {
						display: false
					}
				}
			}
		}
	};
}

export default configs;
