/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable no-underscore-dangle */

/**
 * Main application customers
 */
import "./style.css";
import MDBox from "components/Basics/MDBox";
import DashboardLayout from "components/Advanced/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Advanced/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ChartsActions from "redux-react/actions/chartsActions";
import FormActions from "redux-react/actions/formAction";
import { socket } from "redux-react/middleware/ws";
import { wsEvent } from "redux-react/actions/ws";
import SettingsActions from "redux-react/actions/settingsActions";
import lod_ from "lodash";
import { Icon, IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import i18n from "i18n";
import { useMaterialUIController } from "context";
import ChartsLoader from "components/Custom/ChartsLoader";
import DefaultDataTable from "components/Custom/Tables/DefaultDataTable";
import MDButton from "components/Basics/MDButton";
import { display } from "redux-react/reducers/snackBarReducer";
import ConfirmDialogButton from "components/Custom/Dialogs/ConfirmDialogButton";
import MDTypography from "components/Basics/MDTypography";

import { getLocalStorageBackValues } from "components/Custom/Filters/filters";
import EditChannelDialog from "./EditChannelDialog";

/**
 *
 * @param {Object} route
 *  - key : actual page
 *  - type : type of page
 *  - icon : icon to display in navbar
 *  - route : actual route
 * @returns
 */
export default function ChannelsPage({ route }) {
	const dispatch = useDispatch();
	const { profile, filters } = useSelector(state => state);
	const [controller] = useMaterialUIController();
	const { darkMode } = controller;
	const [dictionaries, setDictionaries] = useState({});
	// Loader while charts are loading
	const [chartsLoading, setChartsLoading] = useState(false);
	// Raw charts from collection "analytics" in DB
	const [rawCharts, setRawCharts] = useState({});
	// Raw charts builded from the back
	const [chartsData, setChartsData] = useState({});
	// Default assistant filters
	const [pageFilters, setPageFilters] = useState([]);
	const [reloadTable, setReloadTable] = useState(false);

	const [openEditChannelDialog, setOpenEditChannelDialog] = useState({
		open: false,
		channel: null
	});

	const [menuRef, setMenuRef] = useState(null);
	const [menuValues, setMenuValues] = useState({});

	const handleCloseDialog = () => {
		setOpenEditChannelDialog({
			open: false,
			channel: null
		});
	};

	const handleSave = item => {
		handleCloseDialog();
		setReloadTable(!reloadTable);
	};

	const handleEdit = item => {
		setOpenEditChannelDialog({
			open: true,
			channel: item.code
		});
	};

	const handleDelete = item => {
		const onSuccess = res => {
			dispatch(
				display({
					message: i18n.t("FORMS.deleteSuccess"),
					type: "success"
				})
			);
			setReloadTable(!reloadTable);
		};
		dispatch(
			FormActions.deleteItem(
				item._id,
				"channel",
				{
					delete: true,
					actions: ["deleteChannelAction"]
				},
				onSuccess
			)
		);
	};

	const testChannelConfig = ({ code }) => {
		dispatch(
			wsEvent({
				event: "test_channel_config",
				payload: { code }
			})
		);
	};

	/**
	 * Get charts data to be displayed in front
	 */
	function getChartsData() {
		return chartsData[route.route] ?? [];
	}
	/**
	 * FIRST STEP : Get raw charts from DB
	 */
	function getRawChartsFromDatabase() {
		const onSuccess = res => {
			setPageFilters(res.filters);
			setRawCharts({ ...rawCharts, [route.route]: res.charts });
		};
		dispatch(ChartsActions.getCharts(profile.assistantID, route, onSuccess));
	}
	/**
	 * SECOND STEP : Build charts with the raw charts on the back
	 */
	function buildRawCharts() {
		// Success handler : set charts data
		const onSuccess = res => {
			setChartsData({ ...chartsData, [route.route]: res.charts });
			setChartsLoading(false);
		};
		// Build charts
		if (!rawCharts[route.route]) return;
		let mandatoryFilters = pageFilters.map(filter => filter.attribute);
		dispatch(
			ChartsActions.buildRawCharts(rawCharts[route.route], filters, mandatoryFilters, onSuccess)
		);
	}
	/**
	 * FIRST STEP : Get raw charts :
	 * - On mount / when page change
	 * - On selected assistant change
	 */
	useEffect(() => {
		setChartsLoading(true);
		getRawChartsFromDatabase();
		// Get dictionaries from catalog
		dispatch(
			SettingsActions.getDictionaries({ query: {}, catalog: "channel" }, res => {
				let newDictionaries = res.dictionaries ?? {};
				setDictionaries(newDictionaries);
			})
		);
	}, [profile.selectedAssistant.assistantID, route]);
	/**
	 * SECOND STEP : Build charts :
	 * - On filters change
	 * - On rawCharts change
	 */
	useEffect(() => {
		buildRawCharts();
	}, [rawCharts]);

	const refreshCharts = () => {
		getRawChartsFromDatabase();
	};

	useEffect(() => {
		socket.on("channel_refresh_list", refreshCharts);

		return () => {
			socket.off("channel_refresh_list", refreshCharts);
		};
	}, []);
	/**
	 * Charts loader
	 */
	if (chartsLoading) {
		return (
			<DashboardLayout>
				<MDBox py={3}>
					<DashboardNavbar />
					<ChartsLoader darkMode={darkMode} />
				</MDBox>
			</DashboardLayout>
		);
	} else
	/**
	 * Main component
	 */
		return (
			<DashboardLayout>
				<MDBox mb={3}>
					<DashboardNavbar
						filters={[
							<MDButton
								style={{ height: "100%", marginRight: "0.75rem" }}
								variant="contained"
								color="info"
								onClick={() => {
									setOpenEditChannelDialog({
										open: true,
										channel: null
									});
								}}
							>
								<Icon>add</Icon>
								{i18n.t("CHANNEL.addCanal")}
							</MDButton>
						]}
					/>
				</MDBox>
				{/*
				 * Pagined table
				 */}
				{getChartsData()
					.filter(chart => chart.type === "paginedList")
					.map((chart, index) => {
						let chartFilters = getLocalStorageBackValues(
							profile.selectedAssistant.assistantID,
							route.route,
							filters
						);
						return (
							<>
								<DefaultDataTable
									key={index}
									form={route.form}
									dictionary={lod_.merge(chart.dictionary, dictionaries)}
									displayDictionaryName={route?.form?.routeDictionary}
									canSearch
									table={chart.data}
									display={chart.request.attributesDisplay}
									pagination={chart.pagination}
									list={chart}
									reloadTable={reloadTable}
									filters={chartFilters}
									actions={[
										<IconButton
											handleclick={({ values }, e) => {
												setMenuValues(values);
												setMenuRef(e.currentTarget);
											}}
										>
											<Icon fontSize="medium">more_vert</Icon>
										</IconButton>
									]}
								/>
								{/* Menu actions selection */}
								<Menu
									open={Boolean(menuRef)}
									anchorEl={menuRef}
									onClose={() => {
										setMenuRef(null);
									}}
								>
									{/* Options */}
									<MenuItem disabled style={{ opacity: 1 }}>
										<MDTypography variant="caption">Edition</MDTypography>
									</MenuItem>
									<MenuItem
										onClick={() => {
											handleEdit(menuValues);
											setMenuRef(null);
										}}
									>
										<Icon fontSize="medium">edit</Icon>&nbsp;{i18n.t("SETTINGS.edit")}
									</MenuItem>

									<ConfirmDialogButton
										onConfirm={(e, values) => {
											handleDelete(menuValues);
											setMenuRef(null);
										}}
										component={
											<MenuItem>
												<Icon fontSize="medium">delete</Icon>&nbsp;{i18n.t("SETTINGS.delete")}
											</MenuItem>
										}
										rowValues={menuValues}
										displayAttribute="name"
									/>
									{/* Config */}
									<MenuItem disabled style={{ opacity: 1 }}>
										<MDTypography variant="caption">Configuration</MDTypography>
									</MenuItem>
									<MenuItem
										onClick={() => {
											testChannelConfig(menuValues);
											setMenuRef(null);
										}}
										// disabled={menuValues?.status === "testing"}
									>
										<Icon fontSize="medium">autorenew</Icon>&nbsp;Tester
									</MenuItem>
								</Menu>
								{/*
								 * Channel configuration
								 */}
								<EditChannelDialog
									open={openEditChannelDialog.open}
									channel={openEditChannelDialog.channel}
									handleClose={handleCloseDialog}
									handleSave={handleSave}
									dictionary={chart.dictionary}
									dictionaryChannelName={route?.form?.routeDictionary}
								/>
							</>
						);
					})}
			</DashboardLayout>
		);
}
